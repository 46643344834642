import React, {useState, Fragment, useEffect, useReducer} from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './CardVerification.css';
import queryString from 'query-string'
import { useMutation } from 'graphql-hooks'
import gql from 'graphql-tag';
import LoadingOverlay from 'react-loading-overlay'
import axios from 'axios';
  
const REDEEM_MUTATION = (token) => `
  mutation {
    virtualCardRedeem(token: "${token}") {
        redeemUrl
        redeemToken
    }
}
`
const axiosGraphQL = axios.create({
    baseURL: process.env.REACT_APP_SYSTEM_URL,
  });

const VirtualCardContent = (props) => {
    const values = queryString.parse(window.location.search);
    const token = values.token;
    const [showSpinner, setShowSpinner] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [showMessage1, setShowMessage1] = useState(false);
    //const [redeemToken] = useMutation(REDEEM_MUTATION)
    useEffect(() => {
        
            try {
                axiosGraphQL
                .post('', {
                query: REDEEM_MUTATION(token),
                })
                .then(result => {
                    //console.log(result.data.data.virtualCardRedeem);
                    if (result.data.data.virtualCardRedeem.redeemToken === 'queued') {
                        setShowMessage(true);
                        setShowSpinner(false);
                    } else if(result.data.data.virtualCardRedeem.redeemToken === 'error') {
                        setShowMessage1(true);
                        setShowSpinner(false);
                    } else {
                        window.location = result.data.data.virtualCardRedeem.redeemUrl;
                    }
                });
            } catch (err) {
                //console.log(err);
                console.log('failed');
            }
      }, []);

    return (
        <>
        <LoadingOverlay
                active={showSpinner}
                spinner
                text="Retrieving your virtual code..."
                >
        <Container fluid>
            <Row className="justify-content-center text-center vh-fill">
                <Col md={10} className="pt-5 align-self-center">
                    <p className="fs-20" style={{ display: ((showMessage) ? 'block' : 'none') }}>
                        This request is taking longer than expected. You will receive an email with your virtual card in the next 2 - 3 hours.
                    </p>
                    <p className="fs-20" style={{ display: ((showMessage) ? 'block' : 'none') }}>
                        We appologize for the inconvenience.
                    </p>
                </Col>
            </Row>
        </Container>
        </LoadingOverlay>
        </>
    );
}

export default VirtualCardContent;