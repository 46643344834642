import React, {Fragment} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './CardVerification.css';


const Footer = () => {


    return (
        <Container fluid className="footer bg-light text-center">
            <Row>
                <Col>
                    <p className="footer-text">© 2022 Best Payment Solutions, Inc.</p>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col>
                    <a href="https://app.termly.io/document/terms-of-use-for-website/8a62abe5-558e-4bd5-9f18-f400014b43c1" target="_blank" className="bps-footer-link-button mx-2">Terms of Service</a>
                    <a href="https://app.termly.io/document/privacy-policy/a800b269-f5d8-4518-b89e-73d8500a29d2" target="_blank" className="bps-footer-link-button mx-2">Privacy Policy</a>
                </Col>
            </Row>
        </Container>
    );

}

export default Footer