import React, {useState, Fragment, useEffect, useReducer} from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import './CardVerification.css';
import gql from 'graphql-tag';
import {useQuery, useMutation} from "@apollo/react-hooks";
import queryString from 'query-string';
import LoadingOverlay from 'react-loading-overlay';
import ReactHtmlParser from 'react-html-parser';

const CardRedeemedContent = (props) => {
    return (
        <Fragment>
            
        <Container fluid className="pt-5">
            <Row className="py-5 bps-bg-light justify-content-center text-center">
                <Col md={6} className="pt-5">
                    <h2 className="bps-midnight fs-48">Visa Reward Card Confirmation</h2>
                    <p className="fs-20">
                    You should receive your card in 10-15 business days.
                    </p>
                </Col>
            </Row>
            
        </Container>
        
        </Fragment>
    );
}

export default CardRedeemedContent;