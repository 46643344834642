import React, {Fragment} from 'react';
import Header from './Header';
import PhysicalCardContent from './PhysicalCardContent';
import Footer from './Footer';

const PhysicalCard = () => {
    return (
        <Fragment>
            <Header />
            <PhysicalCardContent />
            <Footer />
        </Fragment>
    );
}

export default PhysicalCard;