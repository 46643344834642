import React, {Fragment} from 'react';
import { Container, Navbar, Image } from 'react-bootstrap';
import './CardVerification.css';

const Header = () => {

    return (
        <Navbar className="shadow-sm bg-white" fixed="top">
            <Container fluid>
                <Navbar.Brand>
                    <Image fluid
                        src="images/Logo.svg"
                        width="300"
                        className="d-inline-block align-top"
                        alt="Best Payment Solutions logo"
                    />
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
}

export default Header;