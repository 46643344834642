import React, {useState, Fragment, useEffect, useReducer} from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import './CardVerification.css';
import gql from 'graphql-tag';
import {useQuery, useMutation} from "@apollo/react-hooks";
import queryString from 'query-string';
import LoadingOverlay from 'react-loading-overlay';
import ReactHtmlParser from 'react-html-parser';

const query = gql`
  query PhysicalCardAddress($token: String!){
    physicalCardAddress(token: $token) {
        fname
        lname
        email
        phone
        address1
        address2
        city
        state
        zip
        status
    }
  }
`;

const cardMutation = gql`
    mutation PhysicalCardRedeem($token:String!,$fname:String, $lname: String, $email: String, $phone: String, $address1: String, $address2: String, $city: String, $state: String, $zip:String) {
        physicalCardRedeem(input: {token:$token, fname:$fname, lname: $lname, email: $email, phone: $phone, address1: $address1, address2: $address2, city: $city, state: $state, zip:$zip}) {
            message
            error
        }
}
`;

const PhysicalCardContent = (props) => {
    
    const values = queryString.parse(window.location.search);
    //console.log(values);
    //const values = queryString.parse(props.location.search);
    const [showSpinner, setShowSpinner] = useState(true);
    const [showSpinner2, setShowSpinner2] = useState(false);
    const [spinnerText, setSpinnerText] = useState({text: 'Loading your data...'});
    let {loading, data, error} = useQuery(query, {
        variables : 
        {token: values.token}
    });
    const [showSuccessMessage, setshowSuccessMessage] = useState(false);
    const [showErrorMessage, setshowErrorMessage] = useState(false);
    const [hideFields, setHideFields] = useState(false);
    const [errorMessage, seterrorMessage] = useState('');
    const [cardItem] = useMutation(cardMutation);
    const [userFields, setUserFields] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            fname: '',
            lname: '',
            email: '',
            phone: '',address1: '', address2: '', city: '', state: '', zip:'', __typename: ''
        }
    );

    useEffect(() => {
        if (loading) console.log(loading);
        if (error) console.log(error);
        if (!loading && !error && showSpinner) {
            //console.log(data);
            setShowSpinner(false);
            if (data.physicalCardAddress.status !== 'success') {
                if (data.physicalCardAddress.status === 'This token has already been redeemed and cannot be redeemed again') {
                    setHideFields(true);
                }
                setshowErrorMessage(true);
                seterrorMessage(data.physicalCardAddress.status);
            } else {
                setUserFields(data.physicalCardAddress);
                setshowErrorMessage(false);
            }
        } else if (!loading) {
            /*if (data.paymentsRequest.status === 'This token has already been redeemed and cannot be redeemed again') {
                window.location = '/tokenredeemedcustomer';
            }*/
        }
    })

    const handleChange = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setUserFields({[name]: newValue});
    }

    const handleClick = (e) => {
        e.preventDefault();
        setShowSpinner2(true);
        setSpinnerText({text: 'Your card request is being processed...'});
        
        cardItem({
            variables: {
                token: values.token,
                fname: userFields.fname,
                lname: userFields.lname,
                email: userFields.email,
                phone:userFields.phone,
                address1: userFields.address1, 
                address2: userFields.address2, 
                city: userFields.city, 
                state: userFields.state, 
                zip: userFields.zip, 
            }
            }).then(
            res => {
                setShowSpinner2(false);
                //console.log(res);
                if (res.data.physicalCardRedeem.error) {
                    setshowErrorMessage(true);
                    seterrorMessage(res.data.physicalCardRedeem.message);
                    
                } else {
                    window.location = '/physicalcardredeemed';
                    setshowErrorMessage(false);
                    setshowSuccessMessage(true);
                    seterrorMessage(res.data.physicalCardRedeem.message);
                }
                
                /*if (!res.data.physicalCardRedeem.error) {
                    window.location='cussatconfirmation?trackingNo='+res.data.paymentTypeSelection.trackingNo+'&type='+displayFields.paymentType;
                }*/
            },
            err => {setShowSpinner2(false); console.log('err: ',err); }
            );
    }

    return (
        <Fragment>
            <LoadingOverlay
                active={showSpinner || showSpinner2}
                spinner
                text={spinnerText.text}
                >
        <Container fluid className="pt-5">
            <Row className="py-5 bps-bg-light justify-content-center text-center">
                <Col md={6} className="pt-5">
                    <h2 className="bps-midnight fs-48">Your Physical Visa Reward Card is just a click away.</h2>
                    <p className="fs-20">
                        Verify your information and mailing address to receive your reward card.
                    </p>
                </Col>
            </Row>
            <Row className="py-5 justify-content-center">
                <Col md={6}>
                    <Form className="row gx-3 justify-content-center">
                        <h4 className="fs-20 fw-bold bps-midnight">Your Information</h4>
                        <Alert color="success" style={{ display: ((showSuccessMessage) ? 'block' : 'none') }}>
                                You should receive your card in 10-15 business days.
                        </Alert>
                        <Alert color="danger" style={{ display: ((showErrorMessage) ? 'block' : 'none') }}>
                            { ReactHtmlParser (errorMessage) }
                        </Alert>
                        <Form.Group className="mb-3 col-sm-6">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" className="rounded-0" name="fname" defaultValue={userFields.fname}  disabled/>
                        </Form.Group>
                        <Form.Group className="mb-3 col-sm-6">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" className="rounded-0" name="lname" defaultValue={userFields.lname}  disabled/>
                        </Form.Group>
                        <Form.Group className="mb-3 col-sm-6">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" className="rounded-0" name="email" defaultValue={userFields.email} disabled/>
                        </Form.Group>
                        <Form.Group className="mb-3 col-sm-6">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="text" className="rounded-0" name="phone" defaultValue={userFields.phone} disabled />
                        </Form.Group>

                        <h4 className="fs-20 fw-bold bps-midnight pt-4">Mailing Address</h4>
                        <Form.Group className="mb-3 col-sm-6">
                            <Form.Label>Address 1</Form.Label>
                            <Form.Control type="text" className="rounded-0" name="address1" defaultValue={userFields.address1} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3 col-sm-6">
                            <Form.Label>Address 2</Form.Label>
                            <Form.Control type="text" className="rounded-0"  name="address2" defaultValue={userFields.address2} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3 col-sm-6">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" className="rounded-0"  name="city" defaultValue={userFields.city} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3 col-sm-3">
                            <Form.Label>State</Form.Label>
                            <Form.Control type="text" className="rounded-0"  name="state" defaultValue={userFields.state} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3 col-sm-3">
                            <Form.Label>Zip</Form.Label>
                            <Form.Control type="text" className="rounded-0" name="zip" defaultValue={userFields.zip} onChange={handleChange} />
                        </Form.Group>
                        <div style={{ display: ((hideFields) ? 'none' : 'block') }}>
                        <Button type="submit" className="bps-primary-button col-md-2 mt-4"  onClick={(e) => handleClick(e)}>
                            Submit
                        </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
        </LoadingOverlay>
        </Fragment>
    );
}

export default PhysicalCardContent;