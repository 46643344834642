import React, {Fragment} from 'react';
import Header from './Header';
import VirtualCardContent from './VirtualCardContent';
import Footer from './Footer';

const VirtualCard = () => {
    return (
        <Fragment>
            <Header />
            <VirtualCardContent />
            <Footer />
        </Fragment>
    );
}

export default VirtualCard;